<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        <router-link to="/banner" class="back-button"
          ><i class="el-icon-back"></i
        ></router-link>
        <span v-if="$route.name === 'addBanner'">
          Суртчилгаа нэмэх
          <div class="editField">
            <el-row :gutter="16">
              <el-col :span="16" :offset="4">
                <div class="panel">
                  <el-form
                    :model="Banner"
                    :rules="rules"
                    ref="Banner"
                    label-width="220px"
                    class="demo-ruleForm"
                  >
                    <el-form-item label="Cуртчилгааны суваг" prop="channel">
                      <el-select
                        v-model="Banner.channel"
                        placeholder="Суртчилгааны суваг сонгоно уу"
                      >
                        <el-option label="Утас" value="mobile"></el-option>
                        <el-option label="Жолооч" value="driver"></el-option>
                        <el-option
                          label="Харилцагчийн вэб CLIENT"
                          value="client"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="Суртчилгааны гарчиг" prop="title">
                      <el-input
                        style="width: 250px"
                        size="mini"
                        v-model="Banner.title"
                        placeholder="Суртчилгааны гарчиг оруулна уу"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="Суртчилгааны гарчиг англи"
                      prop="title_eng"
                    >
                      <el-input
                        style="width: 250px"
                        size="mini"
                        v-model="Banner.title_eng"
                        placeholder="Суртчилгааны гарчиг оруулна уу"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="Cуртчилгаа харуулах хуудас"
                      prop="page"
                    >
                      <el-select
                        size="mini"
                        v-model="Banner.page"
                        placeholder="Суртчилгааны хуудас сонгоно уу"
                        @change="changePage"
                      >
                        <el-option label="Нүүр" value="home"></el-option>
                        <el-option
                          v-if="Banner.channel === 'mobile'"
                          label="Ресторан"
                          value="restaurant"
                        ></el-option>
                        <el-option
                          v-if="Banner.channel === 'mobile'"
                          label="Төлбөр"
                          value="payment"
                        ></el-option>
                        <el-option
                          v-if="Banner.channel === 'mobile'"
                          label="Хөнгөлөлт"
                          value="sales"
                        ></el-option>
                        <el-option
                          label="Тоглоом"
                          value="game"
                          v-if="Banner.channel === 'mobile'"
                        ></el-option>
                        <el-option
                          label="Захиалга /дэлгэрэнгүй/"
                          value="order"
                          v-if="Banner.channel === 'mobile'"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      v-if="Banner.channel === 'mobile'"
                      label="Cуртчилгааны байрлал"
                      prop="position"
                    >
                      <el-select
                        size="mini"
                        v-model="Banner.position"
                        placeholder="Суртчилгааны байрлал сонгоно уу"
                      >
                        <el-option label="Дээр" value="top"></el-option>
                        <el-option label="Доор" value="bottom"></el-option>
                        <el-option
                          v-if="Banner.page === 'game'"
                          label="Тоглоом"
                          value="game"
                        ></el-option>
                        <el-option
                          v-if="Banner.page === 'order'"
                          label="Захиалга"
                          value="order"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      v-if="Banner.page == 'restaurant'"
                      label="Cуртчилгааны харуулах сэрвес"
                      prop="service"
                    >
                      <el-select
                        size="mini"
                        v-model="Banner.service"
                        placeholder="Суртчилгааны сэрвес сонгоно уу"
                      >
                        <el-option
                          v-for="service in servicesList"
                          :label="service.name_mon"
                          :value="service.name_eng"
                          :key="service.name_eng"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="Cуртчилгааны төрөл" prop="type">
                      <el-select
                        v-model="Banner.type"
                        style="width: 250px"
                        size="mini"
                        placeholder="Суртчилгааны төрөлөө сонгоно уу"
                      >
                        <el-option label="Зураг" value="image"></el-option>
                        <el-option
                          style="width: 250px"
                          size="mini"
                          label="Веб холбоостой"
                          value="webLinking"
                        ></el-option>
                        <el-option
                          style="width: 250px"
                          size="mini"
                          label="Апп дотоод холбоостой"
                          value="deepLinking"
                        ></el-option>
                        <el-option label="popUp" value="popUp"></el-option>
                        <el-option
                          label="Найзаа урих"
                          value="inviteFriend"
                        ></el-option>
                        <el-option label="Тоглоом" value="game"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      v-if="Banner.type == 'popUp'"
                      label="Суртчилгааны тайлбар:"
                      prop="description"
                    >
                      <el-input
                        type="textarea"
                        autosize
                        v-model="Banner.description"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      v-if="Banner.type == 'popUp'"
                      label="Суртчилгааны тайлбар англи:"
                      prop="description_eng"
                    >
                      <el-input
                        type="textarea"
                        autosize
                        v-model="Banner.description_eng"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      v-if="Banner.type == 'popUp'"
                      label="Pop Up зураг url:"
                      prop="popupImage"
                    >
                      <el-input
                        type="url"
                        autosize
                        v-model="Banner.popupImage"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      v-if="Banner.type == 'webLinking'"
                      label-width="200px"
                    >
                      <el-form-item
                        label="Шилжих веб хуудас url:"
                        prop="href_url"
                      >
                        <el-input
                          style="width: 250px"
                          size="mini"
                          type="url"
                          v-model="Banner.href_url"
                        ></el-input>
                      </el-form-item>
                    </el-form-item>
                    <el-form-item
                      v-if="Banner.type == 'deepLinking'"
                      label-width="200px"
                    >
                      <el-form-item label="Апп шилжих хуудас" prop="route">
                        <el-select
                          size="mini"
                          v-model="Banner.route"
                          @change="clearParam()"
                          placeholder="Шилжих хуудас сонгоно уу"
                        >
                          <el-option
                            v-if="
                              Banner.page !== 'home' &&
                                Banner.channel === 'mobile'
                            "
                            label="Үндсэн нүүр хуудас"
                            value="HomeScreen"
                          >
                          </el-option>
                          <el-option
                            v-if="
                              Banner.page !== 'restaurant' &&
                                Banner.channel === 'mobile'
                            "
                            label="Харилцагчдийн жагсаалт хуудас"
                            value="RestaurantScreen"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'mobile'"
                            label="Бүтээгдэхүүний жагсаалт хуудас"
                            value="productListScreen"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'mobile'"
                            label="Бүтээгдэхүүний дэлгэрэнгүй хуудас"
                            value="productInfoScreen"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'mobile'"
                            label="Категори хуудас"
                            value="categoryInfoScreen"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'mobile'"
                            label="Mall Нүүр хуудас"
                            value="mallHomeScreen"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'mobile'"
                            label="Mall Категори хуудас"
                            value="categoryInfoScreenMall"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'mobile'"
                            label="Mall бүтээгдэхүүн хуудас"
                            value="productInfoScreenMall"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'mobile'"
                            label="Mall брэнд хуудас"
                            value="brandInfoScreenMall"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'driver'"
                            label="Profile"
                            value="profile"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'driver'"
                            label="Ажиллах хуваарь"
                            value="schedule"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'driver'"
                            label="Үйлчилгэний төлбөр"
                            value="settlement"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'driver'"
                            label="Хүргэлтийн түүх"
                            value="orderHistory"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'driver'"
                            label="Тоглоом"
                            value="game"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'driver'"
                            label="Үнэлгээ"
                            value="review"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'driver'"
                            label="Найзаа урих"
                            value="invite"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'driver'"
                            label="Багийн мэдээлэл"
                            value="groupInfo"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'driver'"
                            label="Хаягийн сан"
                            value="address"
                          ></el-option>
                          <el-option
                            v-if="Banner.channel === 'driver'"
                            label="Мэдээлэл"
                            value="info"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        v-if="
                          Banner.route == 'RestaurantScreen' ||
                            Banner.route == 'productListScreen' ||
                            Banner.route == 'productInfoScreen' ||
                            Banner.route == 'categoryInfoScreen'
                        "
                        label="Апп дотор шилжих сэрвес"
                        prop="route"
                      >
                        <el-select
                          size="mini"
                          v-model="pagePassProps"
                          @change="getOutletNames()"
                          placeholder="Сервис сонгоно уу"
                        >
                          <el-option
                            v-for="service in servicesList"
                            :label="service.name_mon"
                            :value="service.name_eng"
                            :key="service.name_eng"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        v-if="Banner.route == 'categoryInfoScreenMall'"
                        label="Шилжих mall-ын ангилал"
                        prop="route"
                      >
                        <el-tree
                          :data="mallCategories"
                          :props="defaultProps"
                          @node-click="handleNodeClick"
                        ></el-tree>
                      </el-form-item>
                      <el-form-item
                        v-if="Banner.route == 'productInfoScreenMall'"
                        label="Шилжих mall-ын бүтээгдэхүүн"
                        prop="route"
                      >
                        <el-button
                          size="mini"
                          @click="getMallProduct('button')"
                          type="primary"
                          plain
                          >Бүтээгдэхүүн сонгох</el-button
                        >
                      </el-form-item>
                      <el-form-item
                        v-if="Banner.route == 'brandInfoScreenMall'"
                        label="Шилжих mall-ын брэнд"
                        prop="route"
                      >
                        <el-button
                          size="mini"
                          @click="getMallBrands('button')"
                          type="primary"
                          plain
                          >Брэнд сонгох</el-button
                        >
                      </el-form-item>
                      <el-form-item
                        v-if="
                          (checkMallProduct.length > 0 &&
                            Banner.route === 'productInfoScreenMall') ||
                            (checkMallBrand.length > 0 &&
                              Banner.route === 'brandInfoScreenMall')
                        "
                        :label="
                          Banner.route === 'productInfoScreenMall'
                            ? 'Сонгосон бүтээгдэхүүн'
                            : 'Сонгосон брэнд'
                        "
                      >
                        <div
                          v-for="(product, index) in Banner.route ===
                          'productInfoScreenMall'
                            ? checkMallProduct
                            : checkMallBrand"
                          :key="index"
                        >
                          <el-row :gutter="20">
                            <el-col :span="3">
                              <el-image
                                :src="
                                  Banner.route === 'productInfoScreenMall'
                                    ? product.image[0]
                                    : product.brand_logo[0]
                                "
                                style="width: 50px; height: 50px"
                              ></el-image>
                            </el-col>
                            <el-col :span="10">
                              <span>{{
                                Banner.route === "productInfoScreenMall"
                                  ? product.name_mon
                                  : product.brand_monName
                              }}</span>
                            </el-col>
                            <el-col :span="4">
                              <el-button
                                type="danger"
                                icon="el-icon-delete"
                                circle
                                @click="deleteProduct"
                              ></el-button>
                            </el-col>
                          </el-row>
                        </div>
                      </el-form-item>
                      <el-form-item
                        v-if="
                          Banner.route == 'productListScreen' ||
                            Banner.route == 'productInfoScreen'
                        "
                        label="Апп дотор харилцагчийн нэр"
                        prop="route"
                      >
                        <el-select
                          size="mini"
                          v-model="Banner.outlet_id"
                          placeholder="Харилцагчаа сонгоно уу"
                          @change="getMenusByOutlet()"
                          filterable
                        >
                          <el-option
                            v-for="item in outletList"
                            :key="item._id"
                            :label="item.full_name_eng"
                            :value="item._id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        v-if="Banner.route == 'productInfoScreen'"
                        label="Апп доторх бүтээгдэхүүний нэр"
                        prop="route"
                      >
                        <el-select
                          size="mini"
                          style="width: 300px"
                          v-model="Banner.menu"
                          placeholder="Бүтээгдэхүүн сонгоно уу"
                        >
                          <el-option
                            v-for="item in outletMenus"
                            :key="item._id"
                            :label="item.name_mon"
                            :value="item._id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        v-if="Banner.route == 'categoryInfoScreen'"
                        label="Шилжих категори сонгоно уу"
                        prop="route"
                      >
                        <el-select
                          size="mini"
                          v-model="pageCategory"
                          placeholder="Категори сонгоно уу"
                          @change="calculateCategory"
                        >
                          <el-option
                            v-for="item in categoriesList"
                            :key="item.id"
                            :label="item.name_mon"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-form-item>
                    <el-form-item label="Үргэлжлэх огноо" required>
                      <el-col :span="8">
                        <el-form-item prop="start_date">
                          <el-date-picker
                            type="date"
                            placeholder="Суртчилгаа эхлэх огноо"
                            v-model="Banner.start_date"
                            style="width: 250px"
                            size="mini"
                          ></el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col class="line" :span="1"> - </el-col>
                      <el-col :span="8">
                        <el-form-item prop="end_date">
                          <el-date-picker
                            type="date"
                            placeholder="Суртчилгаа дуусах огноо"
                            v-model="Banner.end_date"
                            style="width: 250px"
                            size="mini"
                          ></el-date-picker>
                        </el-form-item>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="Хугацаа тохируулах">
                      <el-col :span="8">
                        <el-form-item>
                          <el-checkbox v-model="Banner.is_time_limit">
                            Цаг тохируулах
                          </el-checkbox>
                        </el-form-item>
                      </el-col>
                    </el-form-item>
                    <el-form-item
                      label="Баннер нэр"
                      v-if="Banner.is_time_limit === true"
                    >
                      <el-col :span="8">
                        <el-form-item>
                          <el-select
                            v-model="Banner.time_limit_mon_name"
                            placeholder="Монгол"
                            @change="
                              changeBannerName(Banner.time_limit_mon_name)
                            "
                          >
                            <el-option
                              v-for="(item, index) in BannerNames"
                              :key="index"
                              :value="item.name_mon"
                              >{{ item.name_mon }}
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item>
                          <el-select
                            disabled
                            v-model="Banner.time_limit_eng_name"
                            placeholder="English"
                          >
                            <el-option
                              v-for="(item, index) in BannerNames"
                              :key="index"
                              :value="item.name_eng"
                              >{{ item.name_eng }}
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-form-item>
                    <el-form-item
                      label="Нэвтэрсэн байх  эсэх"
                      prop="loginNeeded"
                    >
                      <el-switch v-model="Banner.loginNeeded"></el-switch>
                    </el-form-item>
                    <el-form-item label="Зураг">
                      <base64-upload
                        v-if="
                          Banner.source_url !== null && Banner.source_url !== ''
                        "
                        style="width: 100px; height: 100px"
                        :imageSrc="Banner.source_url"
                        @change="onChangeImage"
                      ></base64-upload>
                      <a v-else v-loading="loadUpload">
                        <base64-upload
                          style="width: 120px"
                          :imageSrc="imageNull"
                          @change="onChangeImage"
                        ></base64-upload>
                      </a>
                      <el-input
                        size="mini"
                        v-model="Banner.source_url"
                        disabled
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Зураг англи">
                      <base64-upload
                        v-if="
                          Banner.source_url_eng !== null &&
                            Banner.source_url_eng !== ''
                        "
                        style="width: 100px; height: 100px"
                        :imageSrc="Banner.source_url_eng"
                        @change="onChangeImageEng"
                      ></base64-upload>
                      <a v-else v-loading="loadUploadEng">
                        <base64-upload
                          style="width: 120px"
                          :imageSrc="imageNullEng"
                          @change="onChangeImageEng"
                        ></base64-upload>
                      </a>
                      <el-input
                        size="mini"
                        v-model="Banner.source_url_eng"
                        disabled
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="sortNumber" prop="sortNumber">
                      <el-input-number
                        :precision="2"
                        :step="0.1"
                        size="mini"
                        v-model="Banner.sortNumber"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item label="Cуртчилгааны төлөв" prop="status">
                      <el-radio
                        v-model="Banner.status"
                        label="active"
                        border
                        size="mini"
                        >Идэвхтэй</el-radio
                      >
                      <el-radio
                        v-model="Banner.status"
                        label="default"
                        border
                        size="mini"
                        >Идэвхгүй</el-radio
                      >
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="save">Нэмэх</el-button>
                      <router-link to="/banner" class="back-button">
                        <el-button> Буцах </el-button>
                      </router-link>
                    </el-form-item>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </div>
        </span>
      </h3>
    </div>
    <el-dialog
      title="Бүтээгдэхүүн"
      :visible.sync="mallProductDialogVisible"
      width="40%"
      height="50%"
      @close="visibleDialog"
    >
      <el-input
        v-model="search_text"
        :placeholder="
          Banner.route === 'productInfoScreenMall'
            ? 'Бүтээгдэхүүн хайх ...'
            : 'Брэнд хайх ...'
        "
        @input="
          Banner.route === 'productInfoScreenMall'
            ? getMallProduct('other')
            : getMallBrands('other')
        "
      >
        <el-button slot="prepend" icon="el-icon-search"> </el-button>
      </el-input>
      <el-row class="mb10" v-if="Banner.route === 'brandInfoScreenMall'">
        <el-button type="info" size="mini" @click="setBrandCharacter('')">
          Бүх брэнд
        </el-button>
        <el-button
          class="mt10"
          v-for="(character, index) in firstCharacters"
          :key="index"
          type="success"
          size="mini"
          @click="setBrandCharacter(character.first_character)"
        >
          {{ character.first_character }}</el-button
        >
      </el-row>
      <el-table
        size="mini"
        :data="
          Banner.route === 'productInfoScreenMall'
            ? mallProducts
            : Banner.route === 'brandInfoScreenMall'
            ? mallBrands
            : []
        "
        v-loading="productMallLoading"
      >
        <el-table-column label="Зураг">
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height: 50px"
              :src="
                Banner.route === 'productInfoScreenMall'
                  ? scope.row.image[0]
                  : scope.row.brand_logo[0]
              "
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          label="Нэр"
          :prop="
            Banner.route === 'productInfoScreenMall'
              ? 'name_mon'
              : 'brand_monName'
          "
        ></el-table-column>
        <el-table-column
          :label="
            Banner.route === 'productInfoScreenMall' ? 'Брэнд' : 'Дэлгэрэнгүй'
          "
          :prop="
            Banner.route === 'productInfoScreenMall'
              ? 'brand_mon_name'
              : 'brand_description'
          "
        ></el-table-column>
        <el-table-column
          :label="Banner.route === 'productInfoScreenMall' ? 'Үнэ' : 'Шимтгэл'"
          :prop="
            Banner.route === 'productInfoScreenMall' ? 'price' : 'fee_percent'
          "
        ></el-table-column>
        <el-table-column
          v-if="Banner.route === 'productInfoScreenMall'"
          label="Үлдэгдэл"
          prop="inventoryCount"
        ></el-table-column>
        <el-table-column label="Сонгох">
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-check"
              circle
              plain
              @click="
                Banner.route === 'productInfoScreenMall'
                  ? setMallProduct(scope.row)
                  : setMallBrand(scope.row)
              "
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="text-right mt10"
        background
        :page-size="size"
        :current-page.sync="currentPage"
        :page-sizes="[5, 10, 20, 50, 100]"
        layout="sizes, prev, pager, next, total"
        @size-change="sizeChangeHandler"
        @current-change="curentPageHandler"
        :total="totalCount"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mallProductDialogVisible = false">Буцах</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
import Base64Upload from "vue-base64-upload";
export default {
  components: {
    Base64Upload
  },
  created() {
    if (this.$route.name === "editBanner") {
      this.getBannerId(this.$route.query.id);
    } else if (this.$route.name === "addBanner") {
      this.$notify({
        title: "Суртчилгааны мэдээлэл нэмэх хуудас",
        message: "Мэдээллээ бүрэн гүйцэт оруулна уу",
        type: "info"
      });
    }
    this.getServices();
    this.getBannerNames();
    this.getAllCategories();
    this.getOutletNames();
  },

  data() {
    return {
      pagePassPropsBrandFilter: {},
      checkMallProduct: [],
      checkMallBrand: [],
      firstCharacters: null,
      productMallLoading: false,
      totalCount: 0,
      mallProducts: null,
      mallBrands: null,
      character: "",
      search_text: "",
      mallProductDialogVisible: false,
      defaultProps: {
        children: "childrens",
        label: "name_mon"
      },
      servicesList: [],
      mallCategories: [],
      url:
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      loadUpload: false,
      loadUploadEng: false,
      imageNull: "none",
      imageNullEng: "none",
      bannerDetail: {},
      Banner: {
        type: "",
        loginNeeded: false,
        passProps: {},
        route: "",
        position: "",
        href_url: "",
        page: "",
        polygon: null,
        description: "",
        title: "",
        source_url: "",
        source_url_eng: "",
        status: "",
        channel: "",
        start_date: "",
        end_date: "",
        alt_attr: "",
        target_attr: "",
        sortNumber: "",
        service: "",
        popupImage: "",
        outlet_name: null,
        menu: null,
        category: null,
        outlet_id: null,
        is_time_limit: false,
        time_limit_mon_name: null,
        time_limit_eng_name: null
      },
      pageCategory: "",
      pagePassProps: "",
      field: "",
      sort: "",
      currentPage: 0,
      size: 5,
      rules: {
        title: [
          {
            required: true,
            message: "Та зарлал нэрээ оруулна уу",
            trigger: "blur"
          },
          {
            min: 3,
            message: "Хамгийн багадаа 3 үсэг оруулна уу",
            trigger: "blur"
          }
        ],
        type: [
          {
            required: true,
            message: "Та суртчилгааны төрөлөө сонгоно уу",
            trigger: "change"
          }
        ],
        start_date: [
          {
            type: "date",
            required: true,
            message: "Эхлүүлэх өдөр сонгоно уу",
            trigger: "change"
          }
        ],
        end_date: [
          {
            type: "date",
            required: true,
            message: "Дуусгах өдөр сонгоно уу",
            trigger: "change"
          }
        ],
        position: [
          {
            required: true,
            message: "Суртчилгааны байршилыг сонгоно уу",
            trigger: "change"
          }
        ],
        channel: [
          {
            required: true,
            message: "Суртчилгааны сувгийг оруулна уу",
            trigger: "change"
          }
        ],
        status: [
          {
            required: null,
            message: "Суртчилгааны байршилыг сонгоно уу",
            trigger: "change"
          }
        ],
        sortNumber: [
          {
            required: true,
            message: "Суртчилгааны байршилыг сонгоно уу",
            trigger: "change"
          }
        ],
        description: [
          {
            required: true,
            message: "Суртчилгааны байршилыг сонгоно уу",
            trigger: "change"
          }
        ],
        alt_attr: [
          {
            required: true,
            message: "Суртчилгааны байршилыг сонгоно уу",
            trigger: "change"
          }
        ],
        target_attr: [
          {
            required: true,
            message: "Суртчилгааны байршилыг сонгоно уу",
            trigger: "change"
          }
        ]
      },
      fromRecord: 0,
      serviceCategory: "",
      outlet: "",
      outletList: [],
      outletMenus: [],
      categoriesList: [],
      BannerNames: []
    };
  },
  methods: {
    setBrandCharacter(character) {
      this.character = character;
      this.getMallBrands("other");
    },

    deleteProduct() {
      if (this.Banner.route === "productInfoScreenMall") {
        this.checkMallProduct = [];
        this.Banner.menu = "";
      } else {
        this.checkMallBrand = [];
      }
    },

    visibleDialog() {
      this.currentPage = 0;
      this.character = "";
      this.search_text = "";
    },

    setMallBrand(data) {
      this.checkMallBrand = [];
      this.mallProductDialogVisible = false;
      this.checkMallBrand.push(data);
      this.pagePassPropsBrandFilter = data;
    },

    setMallProduct(data) {
      this.checkMallProduct = [];
      this.mallProductDialogVisible = false;
      this.Banner.menu = data.productId;
      this.checkMallProduct.push(data);
    },

    getMallBrands(type) {
      if (type === "button") {
        this.openFullScreenLoader(true);
      } else {
        this.productMallLoading = true;
      }
      service
        .getMallBrands(
          this.character,
          this.search_text,
          this.size,
          this.size * (this.currentPage === 0 ? 0 : this.currentPage - 1)
        )
        .then(response => {
          if (response.status === "success") {
            this.mallBrands = response.data;
            this.totalCount = response.total;
            this.firstCharacters = response.firstCharacters;
            this.mallProductDialogVisible = true;
            if (type === "button") {
              this.openFullScreenLoader(false);
            } else {
              this.productMallLoading = false;
            }
          } else {
            this.mallProductDialogVisible = false;
            if (type === "button") {
              this.openFullScreenLoader(false);
            } else {
              this.productMallLoading = false;
            }
          }
        });
    },

    getMallProduct(type) {
      if (type === "button") {
        this.openFullScreenLoader(true);
      } else {
        this.productMallLoading = true;
      }
      const tempBody = {
        from: this.currentPage,
        size: this.size,
        maxPrice: 0,
        minPrice: 0,
        search_text: this.search_text,
        filters: [],
        field: this.field,
        sort: this.sort
      };
      service.getMallProduct(tempBody).then(response => {
        if (response.status === "success") {
          this.mallProducts = response.data.products.hits;
          this.totalCount = response.data.products.total;
          this.mallProductDialogVisible = true;
          if (type === "button") {
            this.openFullScreenLoader(false);
          } else {
            this.productMallLoading = false;
          }
        } else {
          this.mallProductDialogVisible = false;
          if (type === "button") {
            this.openFullScreenLoader(false);
          } else {
            this.productMallLoading = false;
          }
        }
      });
    },

    sizeChangeHandler(size) {
      this.size = size;
      if (this.Banner.route === "productInfoScreenMall") {
        this.getMallProduct("other");
      } else {
        this.getMallBrands("other");
      }
    },

    curentPageHandler(page) {
      this.currentPage = page;
      if (this.Banner.route === "productInfoScreenMall") {
        this.getMallProduct("other");
      } else {
        this.getMallBrands("other");
      }
    },

    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },

    handleNodeClick(data) {
      this.pagePassProps = data.uniq_id;
    },

    getServices() {
      service.getServices(false, "active", true).then(servicesResponse => {
        let b = servicesResponse.data.data.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
      });
    },
    getAllCategories() {
      service.getAllCategories().then(servicesResponse => {
        if (servicesResponse.status === "success") {
          this.mallCategories = servicesResponse.data;
        }
      });
    },
    changePage(page) {
      switch (page) {
        case "sales":
          this.Banner.position = "discount";
          break;
        case "order":
          this.Banner.position = "order";
          break;
        case "game":
          this.Banner.position = "game";
      }
    },
    clearParam() {
      (this.Banner.outlet_name = null),
        (this.pagePassProps = ""),
        (this.Banner.menu = null),
        (this.pageCategory = "");
    },
    calculateCategory() {
      this.categoriesList.forEach(element1 => {
        if (this.pageCategory === element1.id) {
          this.Banner.category.push(element1);
        }
      });
    },
    getCategoriesList() {
      service.getMainCategories("").then(res => {
        this.categoriesList = [];
        for (var i = 0; i < res.length; i++) {
          if (res[i].service == this.pagePassProps) {
            this.categoriesList.push(res[i]);
          }
        }
      });
    },
    getMenusByOutlet() {
      service
        .getMenusByOutlet(this.Banner.outlet_id, true, true)
        .then(menuResponse => {
          this.outletMenus = menuResponse.data.data;
        });
    },
    getOutletNames() {
      service.getOutletNames(this.pagePassProps).then(outletResponse => {
        const services = [];
        this.servicesList.map(el => services.push(el.name_eng));
        this.outletList = outletResponse.data.data;
      });
      this.getCategoriesList();
    },
    save() {
      this.$refs.Banner.validate(valid => {
        if (valid) {
          if (this.$route.name === "addBanner") {
            if (this.Banner.type === "inviteFriend") {
              this.Banner.route = "inviteFriend";
            } else if (this.Banner.type === "game") {
              this.Banner.route = "gameScreen";
            }
            this.Banner.route === "categoryInfoScreenMall"
              ? (this.Banner.passProps = {
                  channel: "toktokmall",
                  data: {
                    filters: [
                      {
                        key: this.pagePassProps
                      }
                    ],
                    name_eng: "Category",
                    name_mon: "Ангилал"
                  }
                })
              : (this.Banner.passProps.serviceData = this.pagePassProps);
            this.Banner.route === "brandInfoScreenMall"
              ? (this.Banner.passProps = {
                  channel: "toktokmall",
                  data: {
                    filters: [
                      {
                        key: this.pagePassPropsBrandFilter.brand_id,
                        brand_logo: this.pagePassPropsBrandFilter.brand_logo,
                        name_eng: this.pagePassPropsBrandFilter.brand_engName,
                        name_mon: this.pagePassPropsBrandFilter.brand_monName
                      }
                    ],
                    name_eng: "Brand",
                    name_mon: "Бренд"
                  }
                })
              : (this.Banner.passProps.serviceData = this.pagePassProps);
            if (this.Banner.position == "discount") {
              this.Banner.page = "home";
            }
            if (this.Banner.outlet_name !== null) {
              this.outletList.forEach(el => {
                if (el.full_name_eng === this.Banner.outlet_name) {
                  this.Banner.outlet_id = el._id;
                }
              });
            }
            service.createBanner(this.Banner).then(response => {
              if (response.data.status === "success") {
                this.$notify({
                  title: "Амжилттай",
                  message: "Суртчилгаа нэмэгдлээ",
                  type: "success"
                });
                this.$router.go(-1);
              } else {
                this.$notify({
                  title: "Амжилтгүй",
                  message:
                    "Суртчилгаа нэмэхэд алдаа гарлаа" +
                    " " +
                    response.error.message,
                  type: "warning"
                });
              }
            });
          }
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    onChangeImage(file) {
      this.loadUpload = true;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("Зурагны хэмжээ ихдээ 1MB байх ёстой!");
        this.imageNull = "none";
        this.loadUpload = false;
      } else {
        if (!file.name) {
          this.$message.error("Зурагны aнгли нэрийг заавал оруулах ёстой!");
          this.imageNull = "none";
          this.loadUpload = false;
        } else {
          if (
            this.Banner.page == "restaurant" &&
            this.Banner.position == "bottom"
          ) {
            let payload = {};
            payload.file = file.base64;
            payload.imageName = file.name;
            payload.size = "1200x300";
            service.uploadAppSize(payload).then(res => {
              this.Banner.source_url = res.img_url;
              this.loadUpload = false;
            });
          } else {
            let payload = {};
            payload.file = file.base64;
            payload.bannerName = file.name;
            service.uploadBannerImage(payload).then(res => {
              this.Banner.source_url = res.data.data.img_url;
              this.loadUpload = false;
            });
          }
        }
      }
      return isLt2M;
    },
    onChangeImageEng(file) {
      this.loadUploadEng = true;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("Зурагны хэмжээ ихдээ 1MB байх ёстой!");
        this.imageNullEng = "none";
        this.loadUploadEng = false;
      } else {
        if (!file.name) {
          this.$message.error("Зурагны aнгли нэрийг заавал оруулах ёстой!");
          this.imageNullEng = "none";
          this.loadUploadEng = false;
        } else {
          if (
            this.Banner.page == "restaurant" &&
            this.Banner.position == "bottom"
          ) {
            let payload = {};
            payload.file = file.base64;
            payload.imageName = file.name;
            payload.size = "1200x300";
            service.uploadAppSize(payload).then(res => {
              this.Banner.source_url_eng = res.img_url;
              this.loadUploadEng = false;
            });
          } else {
            let payload = {};
            payload.file = file.base64;
            payload.bannerName = file.name;
            service.uploadBannerImage(payload).then(res => {
              this.Banner.source_url_eng = res.data.data.img_url;
              this.loadUploadEng = false;
            });
          }
        }
      }
      return isLt2M;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getBannerNames() {
      service.getTimeLimitNamesBanner().then(res => {
        this.BannerNames = res.data.data.rows;
      });
    },
    changeBannerName(data) {
      this.BannerNames.forEach(el => {
        if (el.name_mon === data) {
          this.Banner.time_limit_eng_name = el.name_eng;
        }
      });
    }
  }
};
</script>
<style>
.editField {
  margin-top: 25px;
}
</style>
